@import url(https://fonts.cdnfonts.com/css/breamcatcher);a,abbr,acronym,address,applet,article,aside,audio,b,big,blockquote,body,canvas,caption,center,cite,code,dd,del,details,dfn,div,dl,dt,em,embed,fieldset,figcaption,figure,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,i,iframe,img,ins,kbd,label,legend,li,mark,menu,nav,no-scrollhtml,object,ol,output,p,pre,q,ruby,s,samp,section,small,span,strike,strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,tt,u,ul,var,video {
    border: 0;
    font-size: 100%;
    font: inherit;
    margin: 0;
    padding: 0;
    vertical-align: initial
}

article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section {
    display: block
}

body {
    background-color: #000;
    color: #fdeff4;
    line-height: 1
}

a {
    color: inherit;
    text-decoration: none
}

ol,ul {
    list-style: none
}

blockquote,q {
    quotes: none
}

blockquote:after,blockquote:before,q:after,q:before {
    content: "";
    content: none
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

html {
    font-size: 14px;
    overflow-x: hidden
}

body {
    font-size: 2rem
}

h1 {
    font-size: 3.5rem
}

.no-scroll {
    overflow: hidden
}

#mainpage {
    font-family: Breamcatcher,sans-serif;
    position: relative
}

#mainpage #navbar {
    align-items: center;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    z-index: 50
}

#mainpage #navbar #logo-container {
    padding-left: 2rem;
    padding-top: 1rem
}

#mainpage #navbar #logo-container #logo {
    height: 75px;
    width: 75px
}

#mainpage #navbar #menu-container {
    padding-right: 1rem
}

#mainpage #navbar #menu-container #menu-list {
    display: flex;
    font-size: 2rem;
    gap: 1em
}

#mainpage #navbar #menu-container #menu-list a {
    position: relative;
    text-decoration: none
}

#mainpage #navbar #menu-container #menu-list a:after,#mainpage #navbar #menu-container #menu-list a:before {
    background-color: #fdeff4;
    bottom: -2px;
    content: "";
    height: 2px;
    position: absolute;
    transition: width .3s ease;
    width: 0
}

#mainpage #navbar #menu-container #menu-list a:before {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}

#mainpage #navbar #menu-container #menu-list a:after {
    right: 50%;
    -webkit-transform: translateX(50%);
    transform: translateX(50%)
}

#mainpage #navbar #menu-container #menu-list a:hover {
    cursor: pointer
}

#mainpage #navbar #menu-container #menu-list a:hover:after,#mainpage #navbar #menu-container #menu-list a:hover:before {
    width: 90%
}

#mainpage #welcome-section {
    align-items: center;
    display: flex;
    height: 100svh;
    justify-content: center;
    position: relative;
    width: 100svw
}

#mainpage #welcome-section #welcome-video {
    height: 100%;
    object-fit: cover;
    width: 100%
}

#mainpage #welcome-section #welcome-container {
    -webkit-animation: appier 2s ease-in;
    animation: appier 2s ease-in;
    height: 50%;
    width: 70%;
    z-index: 2
}

#mainpage #welcome-section #welcome-container,#mainpage #welcome-section #welcome-container .middle {
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%)
}

#mainpage #welcome-section #welcome-container .middle {
    width: 100%
}

#mainpage #welcome-section #welcome-container .middle .text-container {
    text-align: center
}

#mainpage #welcome-section #welcome-container .middle .text-container .welcome-header {
    font-size: 12rem
}

#mainpage #welcome-section #welcome-container .middle .text-container .welcome-sub-header {
    font-size: 2.8rem
}

#mainpage #welcome-section #welcome-container .box {
    border: 5px solid #fdeff4;
    height: 70px;
    width: 70px
}

#mainpage #welcome-section #welcome-container .box.topleft {
    border-bottom: none;
    border-right: none;
    left: 0;
    position: absolute;
    top: 0
}

#mainpage #welcome-section #welcome-container .box.topright {
    border-bottom: none;
    border-left: none;
    position: absolute;
    right: 0;
    top: 0
}

#mainpage #welcome-section #welcome-container .box.topright:before {
    -webkit-animation: blink 1.5s infinite;
    animation: blink 1.5s infinite;
    background-color: #c73e1d;
    border-radius: 50%;
    content: "";
    height: 40px;
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: 40px
}

#mainpage #welcome-section #welcome-container .box.botleft {
    border-right: none;
    border-top: none;
    bottom: 0;
    left: 0;
    position: absolute
}

#mainpage #welcome-section #welcome-container .box.botright {
    border-left: none;
    border-top: none;
    bottom: 0;
    position: absolute;
    right: 0
}

#mainpage #gallery-section {
    align-items: center;
    display: grid;
    justify-content: center;
    margin: 5rem auto auto;
    max-width: 1700px;
    min-height: 150svh
}

#mainpage #gallery-section .gallery-content {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center
}

#mainpage #gallery-section .gallery-content .slide-video {
    margin: 20px;
    width: calc(33.33% - 100px);

}

#mainpage #gallery-section .gallery-content .icont {
    padding:56.25%  0 0 0;
    position:relative;
}


#mainpage #gallery-section .gallery-content .slide-video .play-btn {
    position: absolute;
    right: 1rem;
    top: 1rem
}

#mainpage #gallery-section .gallery-content .slide-video .play-btn:before {
    background-color: #000;
    border-radius: 50%;
    content: "";
    height: 50px;
    left: 45%;
    opacity: .6;
    position: absolute;
    top: 45%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    width: 50px
}

#mainpage #gallery-section .gallery-content .slide-video .gallery-video {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height:100%;
}

#mainpage #gallery-section .gallery-content .slide-image {
    margin: 20px;
    width: calc(33.33% - 100px);
    position: relative;
}

#mainpage #gallery-section .gallery-content .slide-image .gallery-image {
    width: 100%
}

#mainpage #gallery-section #loadMoreBtn {
    background-color: transparent;
    border: 3px solid #fdeff4;
    border-radius: 10px;
    color: #fdeff4;
    font-size: 1.5rem;
    margin: auto;
    padding: 1rem;
    transition: background-color .5s ease;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content
}

#mainpage #gallery-section #loadMoreBtn:hover {
    background-color: #fdeff4;
    color: #000;
    cursor: pointer
}

#mainpage #gallery-section #overlay {
    background-color: rgba(0,0,0,.8);
    height: 100svh;
    position: fixed;
    right: 0;
    top: 0;
    width: 100svw;
    z-index: 100
}

#mainpage #gallery-section #overlay .svg:hover {
    cursor: pointer
}

#mainpage #gallery-section #overlay #close-svg {
    position: absolute;
    right: 10px;
    top: 10px
}

#mainpage #gallery-section #overlay #nextBtn {
    position: absolute;
    right: 10px;
    top: 50%;
    transition: -webkit-transform .5s ease;
    transition: transform .5s ease;
    transition: transform .5s ease,-webkit-transform .5s ease;
    z-index: 101
}

#mainpage #gallery-section #overlay #nextBtn:hover {
    -webkit-transform: scale(.8);
    transform: scale(.8)
}

#mainpage #gallery-section #overlay #previousBtn {
    left: 10px;
    position: absolute;
    top: 50%;
    transition: -webkit-transform .5s ease;
    transition: transform .5s ease;
    transition: transform .5s ease,-webkit-transform .5s ease;
    z-index: 101
}

#mainpage #gallery-section #overlay #previousBtn:hover {
    -webkit-transform: scale(.8);
    transform: scale(.8)
}

#mainpage #gallery-section #overlay #previousBtn #previousImg {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1)
}

#mainpage #gallery-section #overlay #active {
    width: calc(43% - 100px)
}

#mainpage #gallery-section #overlay #active,#mainpage #gallery-section #overlay #active-video {
    height: auto;
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%)
}

#mainpage #gallery-section #overlay #active-video {
    width: calc(90% - 100px)
}

#mainpage #review-section {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 5em auto auto;
    max-width: 1700px
}

#mainpage #review-section .review-slide {
    border: 3px solid #fdeff4;
    border-radius: 20px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: 10px;
    padding: 2rem;
    width: calc(33.33% - 100px)
}

#mainpage #review-section .review-slide .review-text-container {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    height: 100%;
    position: relative
}

#mainpage #review-section .review-slide .review-text-container .review-name {
    bottom: 0;
    right: 0;
    text-align: right
}

#mainpage #about-section {
    margin: auto;
    position: relative
}

#mainpage #about-section .about-slide #about-container {
    background-color: #000;
    border: 3px solid #fdeff4;
    font-weight: 700;
    letter-spacing: 2.5px;
    line-height: 120%;
    margin: auto;
    max-width: 1700px;
    padding: 3rem;
    display: flex;
    justify-content: center;
    margin: 10svh 0;
    align-items: center;
}
#mainpage #about-section .about-slide #about-container #about-pic{
    padding: 0 2rem 0 0;
}

#mainpage #contact-section {
    grid-gap: 2rem;
    display: grid;
    gap: 2rem;
    margin: auto;
    max-width: 1700px;
    padding: 5rem 0 0;
    text-align: center
}

#mainpage #contact-section #social-contact {
    align-items: center;
    display: flex;
    gap: 2rem;
    justify-content: center
}

#mainpage #contact-section #form-slide {
    border: 3px solid #fdeff4;
    border-radius: 20px;
    margin: auto;
    padding: 3rem 3rem 5rem;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content
}

#mainpage #contact-section #form-slide #contact-text {
    grid-gap: 2rem;
    display: grid;
    gap: 2rem
}

#mainpage #contact-section #form-slide #form {
    grid-gap: 2rem;
    display: grid;
    gap: 2rem;
    margin-top: 2rem
}

#mainpage #contact-section #form-slide #form .form-container {
    display: flex;
    justify-content: space-between
}

#mainpage #contact-section #form-slide #form .form-small-container {
    grid-gap: .5rem;
    display: grid;
    gap: .5rem
}

#mainpage #contact-section #form-slide #form .form-small-container .form-input {
    background-color: transparent;
    border: 1px solid rgba(253,239,244,.3);
    color: #fdeff4;
    padding: .4rem;
    resize: none
}

#mainpage #contact-section #form-slide #form .form-small-container .form-input::-webkit-input-placeholder {
    color: #fdeff4;
    opacity: .5
}

#mainpage #contact-section #form-slide #form .form-small-container .form-input::placeholder {
    color: #fdeff4;
    opacity: .5
}

#mainpage #contact-section #form-slide #form #submit-btn {
    background-color: transparent;
    border: 3px solid #fdeff4;
    border-radius: 10px;
    color: #fdeff4;
    font-size: 1.5rem;
    margin: auto;
    padding: .5rem 1rem;
    transition: background-color .5s ease;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content
}

#mainpage #contact-section #form-slide #form #submit-btn:hover {
    background-color: #fdeff4;
    color: #000;
    cursor: pointer
}

#adminpage .upload-div {
    margin-top: 3rem
}

#adminpage .upload-div #upload-form {
    display: flex;
    gap: 2rem
}

#adminpage .upload-div .upload-btn {
    background-color: transparent;
    border: 1px solid #fdeff4;
    color: #fdeff4;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: .5rem 1rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content
}

#adminpage .upload-div .upload-btn:hover {
    cursor: pointer;
    -webkit-filter: brightness(.7);
    filter: brightness(.7)
}

#adminpage #admin-gallery-section {
    margin: auto;
    max-width: 1700px
}

#adminpage .pictures-div {
    grid-gap: 2rem;
    display: grid;
    gap: 2rem;
    padding: 1rem
}

#adminpage .pictures-div .pic-div {
    align-items: center;
    border: 1px solid rgba(253,239,244,.5);
    display: flex;
    font-size: 1.7rem;
    justify-content: space-between;
    width: 100%
}

#adminpage .pictures-div .pic-div .admin-gallery-path {
    word-break: break-all
}

#adminpage .pictures-div .pic-div .admin-gallery-btn {
    background-color: transparent;
    border: 1px solid #c73e1d;
    color: #c73e1d;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-right: 1rem;
    padding: .5rem 1rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content
}

#adminpage .pictures-div .pic-div .admin-gallery-btn:hover {
    cursor: pointer;
    -webkit-filter: brightness(2);
    filter: brightness(2)
}

#not-found {
    grid-gap: 3rem;
    display: grid;
    gap: 3rem;
    padding: 3rem;
    text-align: center
}

#not-found #link {
    border: 3px solid #fdeff4;
    margin: auto;
    padding: 1rem;
    transition: background-color .5s ease;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content
}

#not-found #link:hover {
    background-color: #fdeff4;
    color: #000;
    cursor: pointer
}

@-webkit-keyframes blink {
    0% {
        opacity: 1
    }

    50% {
        opacity: .3
    }

    to {
        opacity: 1
    }
}

@keyframes blink {
    0% {
        opacity: 1
    }

    50% {
        opacity: .3
    }

    to {
        opacity: 1
    }
}

@-webkit-keyframes appier {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes appier {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@media only screen and (max-width: 1200px) {
    #mainpage #navbar #menu-container #menu-list {
        font-size:4rem
    }

    #mainpage #gallery-section #overlay #active {
        width: 45%
    }
}

@media only screen and (max-width: 875px) {
    #mainpage #navbar #logo-container #logo {
        height:30px;
        width: 30px
    }

    #mainpage #navbar #menu-container {
        margin-top: 1rem;
        padding-right: .5rem
    }

    #mainpage #navbar #menu-container #menu-list {
        font-size: 3rem
    }

    #mainpage #welcome-section #welcome-container .middle .text-container .welcome-header {
        font-size: 5rem
    }

    #mainpage #welcome-section #welcome-container .middle .text-container .welcome-sub-header {
        font-size: 1.5rem
    }

    #mainpage #gallery-section .gallery-content .slide-video {
        width: calc(50% - 100px)
    }

    #mainpage #gallery-section .gallery-content .slide-image {
        margin: 20px;
        width: calc(50% - 100px)
    }

    #mainpage #gallery-section .gallery-content .slide-image .gallery-image {
        width: 100%
    }

    #mainpage #gallery-section #overlay #active {
        width: 65%
    }

    #mainpage #review-section {
        flex-direction: column
    }

    #mainpage #review-section .review-slide {
        width: calc(50% - 100px)
    }

    #adminpage .upload-div #upload-form {
        flex-direction: column
    }
}

@media(max-width: 600px) {
    #mainpage #navbar #logo-container #logo {
        height:30px;
        width: 30px
    }

    #mainpage #navbar #menu-container {
        margin-top: 1rem;
        padding-right: .5rem
    }

    #mainpage #navbar #menu-container #menu-list {
        font-size: 2rem
    }

    #mainpage #welcome-section #welcome-container .middle .text-container .welcome-header {
        font-size: 5rem
    }

    #mainpage #welcome-section #welcome-container .middle .text-container .welcome-sub-header {
        font-size: 1.5rem
    }

    #mainpage #gallery-section .gallery-content .slide-image {
        margin: 20px;
        width: calc(100% - 100px)
    }

    #mainpage #gallery-section .gallery-content .slide-image .gallery-image {
        width: 100%
    }

    #mainpage #gallery-section .gallery-content .slide-video {
        margin: 20px;
        width: calc(100% - 100px)
    }

    #mainpage #gallery-section #overlay #active {
        width: 90%
    }

    #mainpage #review-section {
        flex-direction: column
    }

    #mainpage #review-section .review-slide {
        width: calc(100% - 100px)
    }

    #mainpage #about-section .about-slide #about-container {
        font-size: 1.3rem;
        letter-spacing: 1px;
        line-height: 110%;
        flex-direction: column;
    }
    #mainpage #about-section .about-slide #about-container #about-pic{
        padding: 0 0 2rem 0;
    }

    #mainpage #contact-section {
        font-size: 2rem
    }

    #mainpage #contact-section #form-slide {
        padding: 1rem
    }

    #mainpage #contact-section #form-slide #contact-text {
        gap: 2rem
    }

    #mainpage #contact-section #form-slide #form {
        gap: 2rem;
        margin-top: 2rem
    }

    #mainpage #contact-section #form-slide #form #submit-btn {
        font-size: 1.5rem
    }

    #mainpage #contact-section #form-slide #form .form-small-container .form-input {
        width: 10rem
    }

    #mainpage #contact-section #form-slide #form .form-small-container #message {
        padding: 0;
        width: 100%
    }
}

@media only screen and (max-width: 400px) {
    #mainpage #navbar #logo-container {
        padding-left:1rem
    }

    #mainpage #navbar #logo-container #logo {
        height: 30px;
        width: 30px
    }

    #mainpage #navbar #menu-container {
        padding-right: .5rem
    }

    #mainpage #navbar #menu-container #menu-list {
        font-size: 1.5rem
    }

    #mainpage #welcome-section #welcome-container .middle .text-container .welcome-header {
        font-size: 3.5rem
    }

    #mainpage #welcome-section #welcome-container .middle .text-container .welcome-sub-header {
        font-size: 1.1rem
    }

    #mainpage #gallery-section .gallery-content .slide-video {
        width: calc(100% - 100px)
    }

    #mainpage #gallery-section .gallery-content .slide-image {
        margin: 20px;
        width: calc(100% - 100px)
    }

    #mainpage #gallery-section .gallery-content .slide-image .gallery-image {
        width: 100%
    }

    #mainpage #about-section .about-slide #about-container {
        font-weight: 700;
        letter-spacing: 1.5px;
        line-height: 110%;
        margin: 1rem;
        padding: 1rem
    }

    #mainpage #contact-section #form-slide #form .form-small-container .form-input {
        width: 7rem
    }

    #mainpage #contact-section #form-slide #form .form-small-container #message {
        padding: 0;
        width: 100%
    }
}
